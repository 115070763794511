:root {
  --primary: #505071;
  --secondary: #003663;
  --light: #cccab5;
  /* eigengrau hex  */
  --background: #16161d;
  --black: #0f0e0c;
  --grey: #282626;
}

h1,
p {
  color: var(--primary);
}

*::selection {
  background-color: olive;
}